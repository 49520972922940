<!--
  修改小组头像弹框
-->
<template>
  <el-dialog
    :visible.sync="editPicture"
    :title="$t('chatter.group.changeTheGroupPicture')"
    :before-close="handleClose"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <!-- <p>您可以上传JPG、GIF或PNG文件，最大文件大小为2M</p> -->
      <p>{{ $t("vue_label_chatter_img_upload") }}</p>
      <div class="uploadBox">
        <!-- 当前头像 -->
        <div class="imgTitle">{{ $t("vue_label_chatter_current_avatar") }}</div>
        <!-- 图片预览 -->
        <img v-if="imageUrl" :src="imageUrl" class="avatar" />
        <svg class="icon avatar" aria-hidden="true" slot="reference" v-else>
          <use href="#icon-groupImgMo"></use>
        </svg>
        <!-- 上传按钮 -->
        <el-upload
          action="#"
          :show-file-list="false"
          :http-request="httpRequest"
          :before-upload="beforeAvatarUpload"
          :on-change="getImgType"
        >
          <div class="imgBtn">
            {{ $t("chatter.group.changeTheGroupPicture") }}
          </div>
        </el-upload>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="editGroupImgCancel">{{
        $t("button_partner_cancel")
      }}</el-button>
      <el-button type="primary" @click="editGroupImgConfirm">{{
        $t("label.ems.confirm")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    editPictureTo: {
      type: Boolean,
      default: false,
    },
    oldImg: {
      type: String,
      default: "",
    },
    groupId: {
      type: String,
      default: "",
    },
  },
  watch: {
    editPictureTo: {
      handler: function (newVal) {
        this.editPicture = newVal;
      },
      immediate: true,
    },
    oldImg: {
      handler: function (newVal) {
        this.imageUrl = newVal;
      },
      immediate: true,
    },
  },
  data() {
    return {
      editPicture: false,
      imageUrl: "",
      imgSrc: "", //转换的图片base64编码
      // 上传头像的对象
      uploadObj: {
        photoType: "",
        groupId: "",
      },
      dataFile: {},
    };
  },
  methods: {
    getImgType(e) {
      switch (e.raw.type) {
        case "image/jpeg":
          this.uploadObj.photoType = "JPG";
          break;
        case "image/png":
          this.uploadObj.photoType = "PNG";
          break;
        case "image/gif":
          this.uploadObj.photoType = "GIF";
          break;
      }
      this.uploadObj.groupId = this.groupId;
    },
    //关闭上传小组头像弹框
    handleClose() {
      this.$emit("editGroupImgCancel");
    },
    // 取消上传头像--将方法传给父组件[dynamic-groupList]
    editGroupImgCancel() {
      this.$emit("editGroupImgCancel");
    },
    // 确定上传头像--将方法传给父组件[dynamic-groupList]
    editGroupImgConfirm() {
      this.$emit("editGroupImgConfirm", this.uploadObj, this.dataFile);
    },
    // 上传图片
    httpRequest(data) {
      this.dataFile = data.file;
      this.imageUrl = URL.createObjectURL(data.file);
      this.getBase64(data.file).then((resBase64) => {
        this.imgSrc = resBase64.split(",")[1]; //直接拿到base64信息
      });
    },
    //这个file参数 也就是文件信息，你使用 插件 时 你就可以打印出文件信息 看看
    getBase64(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        let fileResult = "";
        reader.readAsDataURL(file); //开始转
        reader.onload = function () {
          fileResult = reader.result;
        }; //转 失败
        reader.onerror = function (error) {
          reject(error);
        }; //转 结束  咱就 resolve 出去
        reader.onloadend = function () {
          resolve(fileResult);
        };
      });
    },
    // 上传前格式和图片大小限制
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error(
          this.$i18n.t("vue_label_notice_uploadavatar_format")
        ); //"上传头像图片只能是 JPG/PNG/GIF 格式!"
      }
      if (!isLt2M) {
        this.$message.error(
          this.$i18n.t("vue_label_notice_uploadavatar_exceed")
        ); //"上传头像图片大小不能超过 2MB!"
      }
      return isJPG && isLt2M;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/dialogStyle.scss";
::v-deep .el-dialog {
  width: 552px;
  .el-dialog__body {
    padding: 0;
  }
}
.dialog-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 36px 40px 36px !important;
  p {
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    line-height: 18px;
    text-align: left !important;
    padding-bottom: 10px;
  }
  .uploadBox {
    // border: 1px dashed #dddbda;
    margin: 0 auto;
    width: 162px;
    height: 134px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .imgTitle {
      font-size: 12px !important;
      font-weight: 400;
      color: #333330;
      line-height: 18px;
      margin-bottom: 4px;
    }
    .imgBtn {
      padding: 0 6px;
      // width: 70px;
      height: 26px;
      border-radius: 3px;
      border: 1px solid #006dcc;
      font-size: 12px;
      font-weight: 400;
      color: #006dcc;
      line-height: 23px;
      text-align: center;
      margin: 10px auto;
      cursor: pointer;
    }
    .avatar {
      position: relative;
      width: 88px;
      height: 88px;
      display: block;
    }
    .el-upload-action {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      font-size: 0;
      color: #fff;
      text-align: center;
      line-height: 120px;
    }
    .el-upload-action:hover {
      font-size: 20px;
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}
</style>
