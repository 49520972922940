var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"visible":_vm.membersManage,"title":_vm.$t('label.campaignmember.main.manageMember'),"before-close":_vm.membersManageCancel,"close-on-click-modal":false,"append-to-body":"","width":"552"},on:{"update:visible":function($event){_vm.membersManage=$event}}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"dialog_body"},[_c('div',{staticClass:"div_title"},[_c('el-tabs',{on:{"tab-click":_vm.handleClick},model:{value:(_vm.form.activeName),callback:function ($$v) {_vm.$set(_vm.form, "activeName", $$v)},expression:"form.activeName"}},[_c('el-tab-pane',{attrs:{"label":_vm.$t('chatter.group.allTheMembers'),"name":"all"}}),_c('el-tab-pane',{attrs:{"label":_vm.$t('label_partner_workspace_member'),"name":"member"}})],1),_c('div',{staticClass:"searchPerson"},[_c('el-input',{attrs:{"placeholder":_vm.$t('label.lookup.user')},on:{"input":_vm.getKeyWord},model:{value:(_vm.form.keyWord),callback:function ($$v) {_vm.$set(_vm.form, "keyWord", $$v)},expression:"form.keyWord"}}),_c('button',{on:{"click":_vm.getNewPerson}},[_vm._v(_vm._s(_vm.$t("chatter.group.query")))])],1)],1),_c('div',{staticClass:"div_content"},[_c('el-table',{directives:[{name:"loadmore",rawName:"v-loadmore",value:(_vm.loadMore),expression:"loadMore"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":""}},[_c('el-table-column',{attrs:{"label":_vm.$t('chatter.group.userName'),"width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.name || scope.row.userName)+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('chatter.group.theMembership'),"width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                (scope.row.isNumber === true ||
                  scope.row.userstatus === 'normal') &&
                (scope.row.id === _vm.createdUserId ||
                  scope.row.userid === _vm.createdUserIdGroup)
              )?_c('div',{staticClass:"ownerSp"},[_vm._v(" "+_vm._s(_vm.$t("label.referperson"))+" ")]):_c('div',[(scope.row.isNumber === false)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.addPeopleToGroup(scope.row.id, scope.row.userid)}}},[_vm._v(_vm._s(_vm.$t("label.campaignmember.main.addMember1")))]):_vm._e(),(
                  scope.row.isNumber === true ||
                  scope.row.userstatus === 'normal'
                )?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.delPeopleToGroup(scope.row.id, scope.row.userid)}}},[_vm._v(_vm._s(_vm.$t("vue_label_chatter_cancel_adding")))]):_vm._e()],1)]}}])}),_c('el-table-column',{attrs:{"prop":"role","label":_vm.$t('label_tabpage_rolez')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                scope.row.id === _vm.createdUserId ||
                scope.row.userid === _vm.createdUserIdGroup
              )?_c('div',{staticClass:"ownerSp"},[_vm._v(" "+_vm._s(_vm.$t("label.referperson"))+" ")]):_c('el-select',{attrs:{"disabled":scope.row.userrole === '',"placeholder":_vm.$t('label_tabpage_pleaseSelectz')},on:{"change":function (val) { return _vm.changeUserRole(val, scope.row.id, scope.row.userid); }},model:{value:(scope.row.userrole),callback:function ($$v) {_vm.$set(scope.row, "userrole", $$v)},expression:"scope.row.userrole"}},_vm._l((_vm.roles),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)]}}])})],1)],1)]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.membersManageCancel}},[_vm._v(_vm._s(_vm.$t("button_partner_cancel")))]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.membersManageConfirm}},[_vm._v(_vm._s(_vm.$t("chatter.group.complete")))])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }