<!--
  拒绝加入弹框
-->
<template>
  <el-dialog
    :visible.sync="refusedJoinIn"
    :title="$t('vue_label_chatter_refuse_join')"
    :before-close="handleClose"
    append-to-body
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <div>
        <!-- <p>确定拒绝"{{ name }}"成为小组成员吗?</p> -->
        <p>{{ $t("vue_label_chatter_refucecertification", { name: name }) }}</p>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="refusedJoinInCancel">{{
        $t("button_partner_cancel")
      }}</el-button>
      <el-button type="primary" @click="refusedJoinInConfirm">{{
        $t("label.ems.confirm")
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    refusedJoinInTo: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      refusedJoinIn: false,
    };
  },
  watch: {
    refusedJoinInTo: {
      handler: function (newVal) {
        this.refusedJoinIn = newVal;
      },
      immediate: true,
    },
  },
  methods: {
    handleClose() {
      this.$emit("refusedJoinInCancel");
    },
    refusedJoinInCancel() {
      this.$emit("refusedJoinInCancel");
    },
    refusedJoinInConfirm() {
      this.$emit("refusedJoinInConfirm");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  width: 552px;
  // height: 390px;
}
.dialog-body {
  div {
    p {
      font-size: 16px;
      color: #686868;
    }
  }
}
</style>
